import { saveAs } from 'file-saver'
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom"
import TagList from './TagList';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {Container, Row, Col, Grid} from 'react-bootstrap';
import config from "../config"
import API from '../api/api';
import Router from '../api/router'
import {
    UserCircleIcon,
    XMarkIcon,
    CheckIcon,
    UserIcon,
    LockClosedIcon ,
} from '@heroicons/react/24/outline'

const ExportModal = ({ selection, closeModal }) => {
    const input_size = 42
    console.log("EXPORT - selection", selection)
    
    const navigate = useNavigate()
    const location = useLocation()
    const router   = new Router(navigate, location)
    
    const [pageSize,     setPageSize] = useState("100");
    const [client,       setClient]   = useState("");
    const [version,      setVersion]  = useState("");
    const [status,       setStatus]   = useState(1);
    const [errorMessage, setErrorMessage]   = useState("");
     
    useEffect(() => {
	console.log("Export Modal")
    }, []);

    const onStatusChange = (val) => {
        setStatus(val.target.value)
    }

    
    const saveModal = () => {
	console.log("SAVE MODAL")
	const query = {
	    status: status,
	    fuzzy: 1,
	}
	Object.keys(selection).map((key) => {
	    if (selection[key] !== null) {
		query[key] = selection[key]
	    }
	})
	query.client    = client
	query.version   = version
	query.page_size = pageSize
	console.log(query)
	if (query.country !== undefined && query.country !== null) {
	    query.country = query.country.join("|")
	}
	if (query.tags !== undefined && query.tags !== null) {
	    query.tags = query.tags.join("|")
	}
	console.log("SAVE MODAL - QUERY", query)
      
	API.execute(router, "/person/export", query, (data)=>{setErrorMessage(data)}).then((data) => {
            console.log("DOWNLOAD", data)
	    const now = new Date()
	    const timestamp = now.toISOString().replace('-', '').replace('T', '').replace(':', '')
	    const target = "export_"+timestamp+".csv"
            console.log("DOWNLOAD", target)
            const file = new Blob([data.data], {type: "application/pdf"});
            saveAs(file, target)
	    closeModal(true);
	})
    }


    return (
    	    <Modal
                isOpen={true}
                show={true}
                  onHide={() => {closeModal();}}
                centered>
            <Modal.Header closeButton>
              <Modal.Title>Export Persons</Modal.Title>
            </Modal.Header>

            <Modal.Body className="modal-main">
	        <Container>
                  <Row>
 	            <Col md={12} className="modal-col">
                      {errorMessage}
    		    </Col>
	    	  </Row>
                  <Row>
  	            <Col md={6} className="">
	              Status
	            </Col>
 	            <Col md={6} className="">
	    <select name="status" value={status} onChange={onStatusChange}>
	    <option value={1}>New Leads</option>
	    <option value={10}>First Follow Up</option>
	    <option value={20}>Second Follow Up</option>
	    <option value={30}>Third Follow Up</option>
	    <option value={40}>Fourth Follow Up</option>
	    <option value={100}>Potential Clients</option>
	    <option value={300}>Clients</option>
	    <option value={0}>All</option>
	    </select>
	            </Col>
	    	  </Row>
                  <Row>
  	            <Col md={6} className="">
	              Nr Contacts
	            </Col>
 	            <Col md={6} className="">
	    <input type="text" className="" name="page_size" value={pageSize} placeHolder="nr contacts" onChange={(val) => setPageSize(val.target.value)}/>
	    `
    		    </Col>
	    	  </Row>
                  <Row>
  	            <Col md={6} className="">
	              Client
	            </Col>
 	            <Col md={6} className="">
	              <input type="text" className="" name="client" value={client} placeHolder="client" onChange={(val) => setClient(val.target.value)}/>
    		    </Col>
	    	  </Row>
                  <Row>
  	            <Col md={6} className="">
	              Version
	            </Col>
 	            <Col md={6} className="">
	              <input type="text" className="" name="version" value={version} placeHolder="version" onChange={(val) => setVersion(val.target.value)}/>
    		    </Col>
	    	  </Row>
                  <Row>
  	            <Col md={6} className="">
	            </Col>
 	            <Col md={6} className="">
		      <input type="button" className="modal-button clickable" value="Export" onClick={(e)=>saveModal()}/>
    		    </Col>
	    	  </Row>
	      </Container>
            </Modal.Body>
            <Modal.Footer>
                <Row><Col md="12">
                </Col></Row>
                </Modal.Footer>
            </Modal>
        )
  	      

};

export default ExportModal;
