
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import { useNavigate, useLocation, useSearchParams } from "react-router-dom"
import config from "./config"
import API from './api/api'
import Router from './api/router'
import Select from 'react-select'



import {
    MagnifyingGlassIcon,
    UserCircleIcon,
    CheckIcon,
    UserIcon,
    LockClosedIcon ,
    ArchiveBoxXMarkIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    XCircleIcon,
    PauseCircleIcon,
} from '@heroicons/react/24/outline'
import {Helmet} from "react-helmet";
import Button from 'react-bootstrap/Button';
import {Container, Row, Col, Grid, Pagination} from 'react-bootstrap';
import TagList from './Components/TagList';
import PersonModal from './Components/PersonModal';
import ExportModal from './Components/ExportModal';

import countries from './media/countries.js';
import PageNext from './media/images/page-next-100.png'
import PagePrev from './media/images/page-prev-100.png'
import Logo     from './media/images/logo.png'
import './Components/Status.scss'

const Person = () =>  {
    const navigate = useNavigate()
    const location = useLocation()
    const router   = new Router(navigate, location)

    const STATUS_LIST  = 0
    const STATUS_MODAL = 1
    const STATUS_CAMPAIGN_PERSON = 3
    const STATUS_EXPORT = 5
    
    const [persons, setPersons]   = useState(null)
    const [current, setCurrent] = useState({})
    const [status, setStatus]   = useState(STATUS_LIST)
    const [selection, setSelection] = useState({
        fullname: null,
        organisation: null,
        role: null,
        country: null,
        status: null,
    })
    const [sorter,  setSorter] = useState({})
    const [pagination, setPagination] = useState({page_nr: 0, page_size: 25})
    const [errorMessage, setErrorMessage] = useState("")
    const [validTags,  setValidTags] = useState([])
    const [queryStatus, setQueryStatus] = useState({changed: true,
						    executing: false})
    const [searchParams, setSearchParams] = useSearchParams();
    const person_id = searchParams.get('person_id')

    const status_mapping = {
	"0": "Unknown",
	"1000": "Invested",
	"300":  "Negotiations",
	"200": "Interest",
	"100": "Contact",
	"40":  "Fourth Email",
	"30":  "Third Email",
	"20":  "Second Email",
	"10":  "First Email",
	"1":   "In Database",
	"-10":  "No Interest",
	"-170": "Spam Filter",
	"-180": "Old Email",
	"-190": "Irrelevant",
	"-200": "Invalid",
	"-1000": "No Contact",
    }


    
    useEffect(() => {
        setSelection(prevState => ({...prevState, person_id: person_id}))

    }, [person_id]);
	     
    useEffect(() => {
        console.log("SELECTION")
	getPersons()
    }, [selection]);

    useEffect(() => {
        console.log("PAGINATION")
	getPersons()
    }, [pagination]);

    const country_list = countries.map((country) => {return {value: country, label: country}})
    useEffect(() => {
        const page_size = localStorage.getItem("page_size")
        setPagination({page_nr: 0, page_size: (page_size !== undefined && page_size !== null) ? page_size : 25})
	getValidTags()
	console.log("COUNTRIES", country_list)
	
    }, []);

    const sendStatus = (person, status) => {
	const query = {
	    project_id: person.project_id,
	    person_id:  person.person_id,
	    status:     status,
	}
	API.execute(router, config.api.person.update, query, setErrorMessage).then((response) => {
	    console.log("SetStatus", response)
	    getPersons(true)
	})
    }

    const onTagsSelected = (key, val) => {
	console.log("SelectionChange", val)
	setPageNr(0)
        setSelection(prevState => ({...prevState, [key]: val.map((item) => {return item.value})}))
        setQueryStatus(prevState => ({...prevState, ["changed"]: true}))
	
    }
    
    const onSelectionChange = (val) => {
	console.log("SelectionChange", val)
	setPageNr(0)
        setSelection(prevState => ({...prevState, [val.target.name]: val.target.value}))
        setQueryStatus(prevState => ({...prevState, ["changed"]: true}))
   }

    const setPageSize = (e) => {
	const new_page_size = parseInt(e.target.value)
	const new_page_nr   = parseInt(pagination.page_nr*pagination.page_size)/new_page_size
        setPagination({page_nr: new_page_nr, page_size: new_page_size})
        setQueryStatus(prevState => ({...prevState, ["changed"]: true}))
        localStorage.setItem('page_size', new_page_size);
    }
	      
    const setPageNr = (page_nr) => {
        setPagination({page_nr: page_nr, page_size: pagination.page_size})
        setQueryStatus(prevState => ({...prevState, ["changed"]: true}))
    }

    const onKeyDown = (event) => {
    };

    const onPersons = (result) => {
    }

    const openExport = () => {
        console.log("OPEN EXPORT")
    	setStatus(STATUS_EXPORT)
    };

    const openModal = (person) => {
        console.log("OPENMODAL", person)
        setCurrent(person)
    	setStatus(STATUS_MODAL)
    };

    const closeModal = (reload) => {
        setCurrent(current)
	setStatus(STATUS_LIST)
	if (reload) {
	    getPersons(true)
	}
    };

    const handleTagRemove = (tag) => {

    }
	      
    const handleTagInput = (tag) => {

    }

    
    const getPersons = async (force=false) => {
	console.log("getPersons")
	if (!force && !queryStatus.changed) {
	    return
	}
	if (queryStatus.executing) {
	    return
	}
        setQueryStatus(prevState => ({...prevState, ["changed"]:  false, ["executing"]: true}))

	const query = {
	    page_nr:   pagination.page_nr,
	    page_size: pagination.page_size,
	    fuzzy: 1,
	}
	Object.keys(selection).map((key) => {
	    if (selection[key] !== null) {
	        query[key] = selection[key]
	    }
	})

	if (query.country !== undefined) {
	    query.country = query.country.join("|")
	}
	if (query.tags !== undefined) {
	    query.tags = query.tags.join("|")
	}
	if (query.status !== undefined) {
	    query.status = query.status.join("|")
	}
	console.log("GetPersons - QUERY", query)
	let data = API.execute(router, config.api.person.list, query, setErrorMessage).then((result) => {
	    //if (result === null) {
	    //    setPersons(null)
	    //}
  	    setPersons(result)
	    setQueryStatus(prevState => ({...prevState, ["executing"]: false}),
			   () => {
			       getPersons()
			   })
	})
    }

    const getValidTags = async () => {
	console.log("getValidTags")
	const query = {
	page_nr:   0,
	page_size: 99999999999,
	}

	console.log("GetValidTags - QUERY", query)
	let data = API.execute(router, config.api.tag.list, query, setErrorMessage).then((result) => {
	    console.log("VALIDTAGS - RESULTS", result)
	    if (result === null) {
	        setValidTags([])
    		return
	    }
  	    setValidTags(result.data.sort((a,b) => (a.type > b.type) ? -1 : (a.type < b.type) ? 1 : (a.tag > b.tag) ? 1 : (a.tag < b.tag) ? -1 : 0))
	})
    }



    const renderStatusButtons = (person, status) => {
	if (status < 10 || status > 90) {
	    return (<></>)
	}
	return (<>
		<span display="block">
		<table><tbody><tr>
		<td><CheckCircleIcon class="clickable" height={24} width={24} alt="Contact" onClick={(e) => sendStatus(person, "100")}/></td>
		<td><PauseCircleIcon class="clickable" height={24} width={24} alt="Not Send" onClick={(e) => sendStatus(person, "1")}/></td>
		<td><ArchiveBoxXMarkIcon class="clickable" height={24} width={24} alt="Spam" onClick={(e) => sendStatus(person, "-170")}/></td>
		<td><XCircleIcon class="clickable" height={24} width={24} alt="Invalid" onClick={(e) => sendStatus(person, "-200")}/></td>
		</tr></tbody></table>
		</span>
		</>
	)

    }

    const renderStatus = (person, status) => {
	if (status >= 0 && status <= 1) {
	    return (<p><span className=""></span></p>)
	}
	const badge_class = (status > 0) ? "status-positive-badge": "status-negative-badge"
	const text = (!(status.toString() in status_mapping)) ?  "Unknown" : status_mapping[status.toString()]
	
	return (<p>
		<span className={badge_class}>{text}</span>
		{renderStatusButtons(person, status)}
		</p>
	       )
	
    }

    const renderSearch = () => {
	const valid_status = [{ value: 0, label: 'Unknown' },
			{ value: -10, label: 'Invalid' },
			{ value: -100, label: 'No Contact' },
			     ]
	const tags_list = validTags.filter((tag) => tag.type === 4 || tag.type==3).map((tag)=> {return {value: tag.tag_id, label: tag.tag}})
	
        return (
	        <>
            <Row className="person-content">
		<Col md="2">
		<div className="search-box">
		<input type="text" name="full_name" value={selection.full_name} onChange={onSelectionChange} placeholder="Name"/>
		</div>
		</Col>
		        <Col md="2">
		            <div className="search-box">
		            </div>
		        </Col>
		        <Col md="2">
            		<div className="search-box">
		                <input type="text" name="organisation" value={selection.organisation} onChange={onSelectionChange} placeholder="Organisation"/>
		            </div>
		        </Col>
        		<Col md="2">
		            <div className="search-box">
                		<input type="text" name="role" value={selection.role} onChange={onSelectionChange} placeholder="Role"/>
		            </div>
		        </Col>
            	<Col md="2">
		  <div className="search-box">
		    <Select options={country_list} isMulti onChange={(val) => onTagsSelected("country", val)} name="country" className="search-tags-select"/>
		  </div>
            	</Col>
            	<Col md="2">
		  <div className="search-box">
		    <Select options={tags_list}    isMulti onChange={(val) => onTagsSelected("tags", val)}    name="tags"    className="search-tags-select"/>
		            </div>
            	</Col>
	    </Row>
            <Row className="person-content">
		<Col md={3}>
	          <div className="count-box">
		    Persons selected: {(persons !== null && persons !== undefined) ? persons.count : 0}
                  </div>
		</Col>
		<Col md={3}>
	          <div className="count-box clickable">
		   <input type="button" name="new" value="New" onClick={(e)=>openModal(null)}/>
	          </div>
		</Col>
		<Col md={3}>
	          <div className="count-box clickable">
		   <input type="button" name="export" value="Export Selection" onClick={openExport}/>
                  </div>
                </Col>
             </Row>
		</>
    )}



    
    const renderPagination = () => {
        if (persons===undefined || persons===null) {
            return (<></>)
        }
	const nr_pages = 3;
	const page_nr = pagination["page_nr"]
	const page_max = Math.ceil(persons.count/pagination["page_size"])
	const pages = []
	for (let i=Math.max(0, page_nr-nr_pages); i<=Math.min(page_max-1, page_nr+nr_pages);i++) {
	    pages.push(i)
	}
	    
        return (<>
        <Row className="person-content">
	<Col md="4" className="person-content">
          <div className="contact-box">
	    <Pagination className="justify-content-center">
	      <Pagination.Prev disabled={page_nr===0} onClick={() => setPageNr(page_nr-1)} />
	      {pages.map((i)=>{return ( 
	        <Pagination.Item key={i+1} active={i === page_nr} onClick={() => setPageNr(i)}>{i+1}</Pagination.Item>
	      )})}
	      <Pagination.Next disabled={page_nr===page_max} onClick={() =>setPageNr(page_nr+1)} />
            </Pagination>
	  </div>
 	</Col>
	<Col md="4" className="person-content">
          <div className="contact-box">
              <label htmlFor="postsPerPageSelect" className="form-label">
                Contacts per page:
              </label>
              <select
                className="form-select"
                id="pageSize"
                value={pagination["page_size"]}
                onChange={setPageSize}
              >
                <option value={5}>5  </option>
                <option value={10}>10</option>
	        <option value={25}>25</option>
	        <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={250}>250</option>
                <option value={500}>500</option>
              </select>
	  </div>
	</Col>
      </Row>
      </>
    )}

	
    const renderPersons = () => {
        if (persons===undefined || persons===null) {
            return (<></>)
        }
        console.log("PERSONS", persons)
        const activeClass = "w-5 h-9 text-black-900"
	    const header = ["first_name", "last_name", "organisation", "role"]
        return (<>
        <Row className="person-content">
            {header.map((key, i) => {
    	        return (
    		        <Col md="2" className="person-content">
	                    <div className="contact-box"><b>
		                    {key}
        		        </b></div>
        		    </Col>)
            })}
    	    <Col md="1" className="person-content">
	            <div className="contact-box"><b>
		            country
        		</b></div>
        	</Col>
    		<Col md="1" className="person-content">
	            <div className="contact-box"><b>
		            status
        		</b></div>
        	</Col>
    		<Col md="1" className="person-content">
	            <div className="contact-box"><b>
		            mailings
        		</b></div>
        	</Col>
    		<Col md="1" className="person-content">
	            <div className="contact-box"><b>
		            source
        		</b></div>
        	</Col>
        </Row>
	    {persons.data.map((person, index) => {
	        return (
                <Row className="person-content">
                    {header.map((key, i) => {
                	    const data_class = (index % 2 === 0) ? "person-highlight" : "person"
    	    	        return (
    				<><Col md={2} className="person-content clickable" onClick={(e)=>openModal(person)}>
	                	    <div className="contact-box">
		                        {person[key]}
        		            </div>
        		        </Col></>)
        		    })}
       		        <Col className="person-content clickable" md={1}  onClick={(e)=>openModal(person)}>
	                    <div className="contact-box">
		        {person.country}
        		        </div>
        		    </Col>
       		        <Col className="person-content clickable" md={1}>
	                    <div className="contact-box">
		        {renderStatus(person, person.status)}
        		        </div>
        		    </Col>
       		        <Col className="person-content clickable" md={1}  onClick={(e)=>openModal(person)}>
	                    <div className="contact-box">
		                    <TagList tags={person.tagged} handleTagInput={handleTagInput} handleTagRemove={handleTagRemove} tagType={3}/>
        		        </div>
        		    </Col>
       		        <Col className="person-content clickable" md={1}  onClick={(e)=>openModal(person)}>
	                    <div className="contact-box">
		        <TagList tags={person.tagged} handleTagInput={handleTagInput} handleTagRemove={handleTagRemove}  tagType={4}/>
        		        </div>
        		    </Col>
                </Row>

        )})}
        </>
    )}



    return (
        <>
		<Helmet>
		  <title>Person List</title>
		</Helmet>
        <div section className="block text-center contcts-box">
            <Container className="person-list">
	    {renderSearch()}
	    {renderPersons()}
	    {renderPagination()}
            </Container>
            <div className="person-list">
	    {status === STATUS_MODAL ? <PersonModal
	     person={current}
	     closeModal={closeModal}
	     /> : <></>}
            </div>
            <div className="person-list">
	    {status === STATUS_EXPORT ? <ExportModal
	     selection={selection}
	     closeModal={closeModal}
	     /> : <></>}
            </div>
        </div>
        </>
	)

}



export default Person



