import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom"
import TagList from './TagList';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {Container, Row, Col, Grid} from 'react-bootstrap';
import config from "../config"
import API from '../api/api';
import Router from '../api/router'
import {
    UserCircleIcon,
    XMarkIcon,
    CheckIcon,
    UserIcon,
    LockClosedIcon ,
} from '@heroicons/react/24/outline'

const PersonModal = ({ person, closeModal }) => {
    const input_size = 42
    
    console.log("PersonModal", person)
    const navigate = useNavigate()
    const location = useLocation()
    const router   = new Router(navigate, location)
    
    const [editable, setEditable] = useState(false);
    const [current, setCurrent]   = useState(person)
    const [errorMessage, setErrorMessage]   = useState("");
    const [changed, setChanged]   = useState({})

     
    useEffect(() => {
	console.log("getPerson - ", person)
    	getPerson(person);
    }, []);

    const getPerson = async (person) => {
	if (person === null || person.person_id === undefined) {
	    return
	}
	const query = {
	    project_id: person.project_id,
	    person_id: person.person_id,
	}

	console.log("GetPersons - QUERY", query)
	let data = API.execute(router, config.api.person.get, query, setErrorMessage).then((result) => {
	    console.log("GETPERSON", result)
	    if (result === null || result.data === undefined) {
		console.log("UNDEFINED PERSON")
		setCurrent(null)
	    }
	    result.data.current_note = ""
	    setChanged({})
  	    setCurrent(result.data)
	})
    }


    const onEdit = (val) => {
	const key = val.target.name
	//console.log("ONEDIT", current, key, val.target.value)
	let value = val.target.value
	setChanged(prevState => ({...prevState, [key]: true}))
	if (current === null) {
	    setCurrent({[key]: value})
	    return
	}
	setCurrent(prevState => ({...prevState, [key]: value}))
	console.log(current)
  }

  const editModal = (current) => {
    setEditable(true)
  };

  const saveModal = () => {
      setEditable(false)
      console.log("SAVEModal", current)
      console.log("CHANGED", changed, Object.values(changed), Object.values(changed).includes(true))
      if (current === null) {
	  return
      }
      if (!(Object.values(changed).includes(true))) {
	  return
      }
      API.execute(router, config.api.person.update, current, setErrorMessage).then((response) => { console.log(response) })
      console.log("CURRENT.current_note", current.current_note, changed.current_note, (current.current_note !== undefined && current.current_note !== ""))
      if (current.current_note !== undefined && current.current_note !== "") {
	  console.log("SAVEModal - NOTE", current.current_note)
          const note = {
	      project_id: current.project_id,
	      person_id: current.person_id,
	      status:     0,
	      note:       current.current_note,
	      type:       1}
	  API.execute(router, config.api.person.note, note, setErrorMessage).then((response) => { console.log(response) })
      }
      
      if (current.notes !== []) {
	  current.notes.map((note) => {
	      const key ="note_"+note.note_id
	      console.log("SAVEModal", key, current.key)
	      if (changed[key] === undefined || changed[key] !== true) {
		  return
	      }
	      if (!(key in current)) {
		  return
	      }
              const note_req = {
		  project_id: current.project_id,
		  note_id:    note.note_id,
		  person_id:  current.person_id,
		  status:     1,
		  note:       note.note,
		  type:       1}
	      API.execute(router, config.api.person.note, note_req, setErrorMessage).then((response) => { console.log(response) })

	  })
      
      };
      getPerson(current);
  };

    const handleTagRemove = (tag) => {

    }
	      
    const handleTagInput = (tag) => {

    }



    //show={showModal}
    //                onHide={closeModal}
    return (
    	    <Modal
                isOpen={true}
                show={true}
        onHide={() => {
	    const has_changed = !(Object.values(changed).includes(true));
	    console.log("HAS CHANGED", has_changed);
	    saveModal();
	    closeModal(has_changed);}}
                centered>
            <Modal.Header closeButton>
            <Modal.Title>{(current!==null) ? current.full_name : ""} - {(current !== null) ? current.organisation : ""}</Modal.Title>
            </Modal.Header>

            <Modal.Body className="modal-main">
                <Row>
	            	<Col md="12">
            {errorMessage}
    		        </Col>
	    	    </Row>
                <Row><Col md="12"><table width="100%"><tbody width="100%">

                    <tr>
                        <td className="modal-col">  </td>
                        <td className="modal-col"><UserCircleIcon height={48} width={48} alt="Organisation"/></td>
                    </tr>

                    <tr>
                        <td className="modal-col">  </td>
                        <td className="modal-col">
 		        {editable ?
			   <input type="button" className="modal-button clickable" value="Save" onclick={(e)=>saveModal()}/> :
			 <input type="button" className="modal-button clickable" value="Edit" onclick={(e)=>editModal()}/> }
  	                </td>
                 </tr>
                    <tr>
                        <td className="modal-col"> ID </td>
            <td className="modal-col">{current.person_id}</td>
                    </tr>
                    <tr>
                        <td className="modal-col"> First Name </td>
            <td className="modal-col"><input type="text" name="first_name" size={input_size} value={(current !== null) ? current.first_name : ""} onChange={onEdit} editable={editable}/></td>
                    </tr>
                    <tr>
                        <td className="modal-col"> Last Name </td>
        <td className="modal-col"><input type="text" name="last_name" size={input_size} value={(current !== null) ? current.last_name : ""} onChange={onEdit} editable={editable}/></td>
                    </tr>
                    <tr>
                        <td className="modal-col"> Organisation </td>
        <td className="modal-col"><input type="text" name="organisation" size={input_size} value={(current !== null) ? current.organisation : ""} onChange={onEdit} editable={editable}/></td>
                    </tr>
                    <tr>
                        <td className="modal-col"> Email </td>
        <td className="modal-col"><input type="text" name="email" size={input_size} value={(current !== null) ? current.email : ""} onChange={onEdit} editable={editable}/></td>
                    </tr>
                    <tr>
                        <td className="modal-col"> Alternative Email </td>
        <td className="modal-col"><input type="text" name="alternative_email" size={input_size} value={(current !== null) ? current.alternative_email : ""} onChange={onEdit} editable={editable}/></td>
                    </tr>
                    <tr>
                        <td className="modal-col"> Phone </td>
        <td className="modal-col"><input type="text" name="phone"  size={input_size} value={(current !== null) ? current.phone : ""} onChange={onEdit} editable={editable}/></td>
                    </tr>
                    <tr>
                        <td className="modal-col"> Role </td>
        <td className="modal-col"><input type="text" name="role"  size={input_size} value={(current !== null) ? current.role : ""} onChange={onEdit} editable={editable}/></td>
                </tr>
                    <tr>
            <td className="modal-col"> Tags </td>
	    {current !== null ? (<td className="modal-col"><TagList tags={current.tagged}  size={input_size} handleTagInput={handleTagInput} handleTagRemove={handleTagRemove} /></td>) : ""}
                </tr>
                    <tr>
                        <td className="modal-col"> Last Update </td>
        <td className="modal-col"><input type="text" name="last_person" value={(current !== null) ? current.last_update : ""} editable={editable}/></td>
                </tr>
                    <tr>
                        <td className="modal-col"> No Contact </td>
            <td className="modal-col"><input type="checkbox" name="no_contact" value={(current !== null) ? current.no_contact : ""} onChange={onEdit} editable={editable}/></td>
                </tr>
                    <tr>
                        <td className="modal-col"> Status </td>
        <td className="modal-col">{(current !== null) ? current.status : 0}
	    <select name="status" value={(current !== null) ? current.status : 0} onChange={onEdit} editable={editable}>
	  <option value={0}>Unknown</option>
	  <option value={1000}>Invested</option>
	  <option value={300}>Negotiations</option>
	  <option value={200}>Interest</option>
	  <option value={100}>Contact</option>
	  <option value={40}>Fourth Email Send</option>
	  <option value={30}>Third Email Send</option>
	  <option value={10}>Second Email Send</option>
	  <option value={10}>First Email Send</option>
	  <option value={1}>In Database</option>
	  <option value={-10}>No Interest</option>
	  <option value={-170}>Spam Filter</option>
	  <option value={-180}>Old Email</option>
	  <option value={-190}>Irrelevant</option>
	  <option value={-200}>Invalid</option>
	  <option value={-100}>No Contact</option>
      </select>
      </td>
                </tr>
                <tr>
                   <td className="modal-col"> Notes </td>
	    <td className="modal-col">
	    <div className="text-center mb-6 clickable">
	    <textarea rows={4} cols={input_size} name="current_note" value={current.current_note} onChange={onEdit} editable={editable}/></div>

	{(current !== null && current.notes !== undefined) ? current.notes.map((note, index)=>{return (
		<div className="text-center mb-6 clickable"><textarea rows={2} cols={input_size} name={"notes_"+note.note_id} onChange={onEdit} value={note.note} editable={editable}/></div>
	)}) : <></>}
	</td>
            </tr>
	
                </tbody></table></Col></Row>
            </Modal.Body>
            <Modal.Footer>
                <Row><Col md="12">
                </Col></Row>
                </Modal.Footer>
            </Modal>
        )
  	      

};

export default PersonModal;
