import React, { useState } from 'react';
import './TagList.scss'

const TagList = ({ tags, handleTagInput, handleTagRemove, tagType }) => {
  const [showTagInput, setShowTagInput] = useState(false);

  return (
    <div className="d-flex flex-wrap">
      {tags !== undefined &&
        Array.isArray(tags) &&
       tags.map((tag) => {
	   if ( tagType === null || tagType === undefined || tagType === tag.type ) {
	       return(
		       <p>
            <span
              className="custom-badge"
              key={tag.tag}
              onClick={() => handleTagRemove(tag)}
            >
              {tag.tag} &times;
            </span>
		       </p>)
           }})}

      {showTagInput ? (
        <input
          type="text"
          className="form-control w-auto"
          placeholder="Add tag..."
          onKeyDown={handleTagInput}
          onBlur={() => setShowTagInput(false)}
          autoFocus
        />
      ) : (
        <p>
          <span
            className="addTag"
            onClick={() => setShowTagInput(true)}
            style={{ cursor: 'pointer' }}
          >
            +
          </span>
        </p>
      )}
    </div>
  );
};

export default TagList;
