import { ThemeProvider } from 'styled-components'

import React from 'react';

//import React, { Component } from 'react';
//import { Provider, Observer  } from 'mobx-react-lite'

import 'bootstrap/dist/css/bootstrap.min.css';

import colors from 'tailwindcss/colors'
import {
  BrowserRouter as BrowserRouter,
  Routes,
  Navigate,
  Route,
} from "react-router-dom";

import Header from './Header'
import Footer from './Footer'

import Person from './Person'
//import Campaign from './Campaign'
//import Organisation from './Organisation'
//import Tag from './Tag'

import Login from './Login/Login'
//import Reset from './Login/Reset'
import Landing from './Login/Landing'
import API from './api/api'
//import Profile from './Profile/'
import LoginSuccess from './Login/Success'
import config from './config'
//import Dashboard from './Dashboard'
//import Front from './Front'

import './App.scss'


const App = () => {
    console.log("APP")
    console.log(API.loggedIn())
    return (
        <>
           <BrowserRouter>
                <Header/>
                {(API.loggedIn()) ?
                <>
                <Routes>
                    <Route path={config.route.overview} element={<Person/>}/>
                    <Route path={config.route.person} element={<Person/>}/>
                    <Route path={config.route.base} element={<Person/>}/>
                    <Route path={config.route.landing} element={<Landing/>}/>
                    <Route path={config.route.loginsuccess} element={<LoginSuccess/>}/>
                </Routes>
                </> : <>
                <Routes>
                    <Route path={config.route.login} element={<Login/>}/>
                    <Route path={config.route.overview} element={<Login/>}/>
                    <Route path={config.route.base} element={<Login/>}/>
                    <Route path={config.route.landing} element={<Login/>}/>
                </Routes>
                </>}
                <Footer/>
            </BrowserRouter>
        </>

    )

}

export default App
