const config = {
    pageName: "A-DCM Risk Management",
    
    route: {
	    //landing: "http://localhost:6110",
	    url:           "https://contacts.sikkemasoftware.nl",
	    base:          "/",
	    overview:      "/person",
	    landing:       "/landing",
	    login:         "/login",
	    loginsuccess:  "/loginsuccess",
	    profile:       "/profile",
	    reset:         "/reset",
	    resetSubmit:   "/resetSubmit",
	    person:        "/person",
	    campaign:      "/campaign",
	    organisation:  "/organisation",
	    tag:           "/tag",
    },
    api: {
    	//url:       'http://127.0.0.1:6214/api/v01',
    	url:       'https://api.sikkemasoftware.nl:6214/api/v01',
	login:      '/user/login',
	user:{
	    get: '/user/get',
	},
	person: {
	    add:      '/person/add',
	    get:      '/person/get',
	    list:     '/person/list',
	    update:   '/person/update',
	    note:     '/person/note',
	    tag:      '/person/tag',
	},
	campaign: {
	    add:      '/campaign/add',
	    get:      '/campaign/get',
	    list:     '/campaign/list',
	    update:   '/campaign/update',
	    note:     '/campaign/note',
	    tag:      '/campaign/tag',
	},
	contact: {
	    update:   '/contact/update',
	},
	organisation: {
	    add:      '/organisation/add',
	    get:      '/organisation/get',
	    list:     '/organisation/list',
	    update:   '/organisation/update',
	    note:     '/organisation/note',
	    tag:      '/organisation/tag',
	},
	tag: {
	    add:      '/tag/add',
	    get:      '/tag/get',
	    list:     '/tag/list',
	    update:   '/tag/update',
	},
    },
};
  

export default config;


